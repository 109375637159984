<template>
    <el-aside width="200px">
        <el-row class="tac">
            <el-col :span="24">
            <el-menu class="el-menu-vertical-demo" :default-active="activeName" style="height:calc(100vh - 60px)">
                <template v-for="(item, key) in getSunMenuList" :key="key">
                    <div v-if="haveChildrenShow(item.children)">
                    <el-sub-menu :index="item._pageId">
                        <template #title>
                        <component
                            :is="item.icon"
                            style="width: 18px; height: 18px; margin-right: 5px"
                        />
                        <span>{{ item.title }}</span>
                        </template>
                        <el-menu-item v-for="item in item.children" :key="item._pageId">
                        <router-link :to="item.path">
                            <span>{{ item.title }}</span>
                        </router-link>
                        </el-menu-item>
                    </el-sub-menu>
                    </div>
                    <div v-else>
                        <el-menu-item @click="gopath(item.path)" :index="item._pageId">
                            <span>{{item.title}}</span>
                        </el-menu-item>
                    </div>
                </template>
            </el-menu>
            </el-col>
        </el-row>
    </el-aside>
</template>
<script>
import { defineComponent, ref, watch } from 'vue'
import {
    mapGetters,
} from 'vuex'
export default defineComponent({
  setup() {
    const selectedKeys = ref(['1'])
    const openKeys = ref(['sub1'])
    watch(
      () => openKeys,
      val => {
        console.log('openKeys', val);
      },
    )
    return {
        selectedKeys,
        openKeys,
    }
  },
  data() {
      return {
          menuNav: [],
          activeName: null,
          oldActiveName: null,
          openName: [],
          breadcrumb: [],
          allSubMenu: [],
          currentPage: {},
      }
  },
  computed: {
        ...mapGetters('menu', [
            'getSecondLevelMenu',
            'getBreadcrumb',
            'getAllSubMenu'
        ]),
        getSunMenuList() {
            let list = [];
            for (let item of this.menuNav) {
                list.push(item)
            }
            list = list.sort((a, b) => {
                return (a.zIndex || 0) - (b.zIndex || 0)
            })
            return list;
        },
        isDisappear() {
            let isDisappear = false //是否显示
            if (this.menuNav) {
                for (let k in this.menuNav) {
                    if (this.menuNav[k].show !== false) {
                        isDisappear = true
                    }
                }
            }
            return isDisappear
        }
    },
    methods: {
        gopath(url){
            this.$router.push({
                path:url
            })
        },
        haveChildrenShow(children) {
            let show = false
            for (let k in children) {
                if (children[k].show !== false) {
                    show = true
                    break
                }
            }
            return show
        },
        handleSelect(name) {
            let path = ''
            let menu = {}
            for (let key in this.allSubMenu) {
                menu = this.allSubMenu[key]
                if (name == menu._pageId) {
                    path = menu.redirect || menu.path
                }
                if (path && path != this.$route.path) {
                    this.$router.push({
                        path
                    })
                    break
                }
            }
        },
    },
    watch: {
        '$route.path': {
            immediate: true,
            handler(path) {
                this.breadcrumb = this.getBreadcrumb(this.$route.path);
                this.currentPage = this.breadcrumb[this.breadcrumb.length - 1];
                this.menuNav = this.getSecondLevelMenu(path.split('/')[1]);
                let openName = []
                let activeName = ''
                let menu = {}
                let haveChildrenShow = false;
                this.$nextTick(() => {
                    if (this.breadcrumb.length > 1) {
                        //二级菜单
                        let val = this.breadcrumb[1]
                        if(this.breadcrumb.length > 2 && this.breadcrumb[0].name === "应用" && this.breadcrumb[1].name !== "应用中心") {
                            val = this.breadcrumb[2]
                        }
                        for (let key in this.getSunMenuList) {
                            menu = this.getSunMenuList[key]
                            if (val._pageId === menu._pageId) {
                                for (let k in menu.children) {
                                    if (menu.children[k].show !== false) {
                                        haveChildrenShow = true;
                                        break;
                                    }
                                }
                                if (haveChildrenShow) {
                                    openName = [key * 1]
                                }
                                activeName = val._pageId
                                break
                            }
                        }
                    }
                    let allSubMenu = this.getAllSubMenu(path.split('/')[1])
                    this.allSubMenu = allSubMenu
                    if (this.breadcrumb.length > 2 && haveChildrenShow) {
                        //三级菜单
                        let val = this.breadcrumb[2]
                        if(this.breadcrumb.length > 2 && this.breadcrumb[0].name === "应用" && this.breadcrumb[1].name !== "应用中心") {
                            val = this.breadcrumb[3]
                        }
                        for (let key in allSubMenu) {
                            menu = allSubMenu[key]
                            if (val._pageId === menu._pageId && menu.show === true) {
                                activeName = val._pageId
                                break
                            } else {//隐藏的菜单不改变选中状态
                                activeName = this.oldActiveName
                            }
                        }
                    }
                    this.openName = openName
                    this.$nextTick(() => {
                        this.activeName = activeName
                        this.oldActiveName = activeName//上次选择的菜单
                    })
                })
            }
        }
    }
})
</script>
<style>
.el-header {
    --el-header-padding: 0;
    padding: 0;
    width: 100%;
}
.el-aside{
    height: 100%;
}
</style>